import pond from './filepond';
import filesize from 'filesize';
import percentage from 'calculate-percentages';
import showError from './error-box';

$('.btn.compress').on('click', () => {
  if (pond.status !== FilePond.Status.READY) {
    return;
  }
  $('#uploader-wrapper').fadeOut(50);
  $('#compress-area').fadeOut(50);
  $('#spinner').fadeIn(50);
  const ids = pond.getFiles().map(file => file.serverId);
  $.ajax({
    method: 'POST',
    url: `${baseUrl}/compress`,
    data: JSON.stringify({
      ids,
      quality: $('input.slider').val()
    }),
    processData: false,
    contentType: 'application/json;charset=UTF-8',
    dataType: 'json',
    success(data) {
      const files = pond.getFiles();
      const images = files.map(file => {
        data.some(image => {
          if (image.id === file.serverId) {
            file.compressedSize = image.size;
            return true;
          }
        });
        return {
          name: file.filename,
          id: file.serverId,
          size: filesize(file.fileSize, { base: 2 }),
          compressedSize: filesize(file.compressedSize, { base: 2 }),
          percentage: Math.ceil(
            percentage.absoluteDifferenceBetween(
              file.fileSize,
              file.compressedSize
            )
          )
        };
      });

      images.forEach(image => $('#result').append(downloadItemTemplate(image)));
      const params = ids.map(id => 'ids[]=' + id);
      $('#result').append(downloadAllTemplate(params));
      $('#spinner').fadeOut(50);
      $('#result').fadeIn(50);
    },
    error(xhr, _, error) {
      $('#spinner').fadeOut(50);
      $('#uploader-wrapper').fadeIn(50);
      $('#compress-area').fadeIn(50);
      // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
      switch (xhr.readyState) {
        case 0:
          // network error
          showError(locales.networkError);
          break;

        case 4:
          // HTTP error
          showError(error);
          break;
        default:
          // something weird happened
          break;
      }
    }
  });
});
const downloadItemTemplate = ({
  name,
  id,
  size,
  percentage,
  compressedSize
}) => `<div class="template d-flex align-items-center row g-0 p-3 rounded border border-opacity-25 border-secondary mb-3">
  <div class="d-flex align-items-center col-12 col-sm-3 mb-3 mb-sm-0">
      <span class="name d-inline-block me-3 fw-500 text-truncate">${name}</span>
  </div>
  <div class="col-12 col-sm-3 text-sm-end mb-3 mb-sm-0">
      <span class="saved p-2 rounded-1 border d-block d-sm-inline w-100 me-3">${locales.saved} ${percentage}%</span>
  </div>
  <div class="col-12 col-sm-3 mb-3 mb-sm-0">
      <span class="d-inline-block original-size text-muted small text-decoration-line-through">${size}</span>
      <span class="d-inline-block new-size text-muted small me-4">${compressedSize}</span>
  </div>
  <div class="actions col-12 col-sm-3 text-sm-end">
      <a class="btn btn-primary text-white download" href="${baseUrl}/download?image=${id}/${name}"  target="_blanks">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download mb-1">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="7 10 12 15 17 10"></polyline>
              <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
          ${locales.download}
      </a>
  </div>
</div>`;

const downloadAllTemplate =
  params => `<div class="all row gx-0 p-3 border rounded-1">
  <div class="col-12 col-sm-7 mb-2 mb-sm-0">
      <a class="btn compress-another border me-2 fw-500 rounded-1" href="/">
      ${locales.compressMore}!
      </a>
      <button class="btn border delete-all rounded-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2">
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
      </button>
  </div>
  <div class="col-12 col-sm-5 text-sm-end">
      <a class="download-all btn btn-primary text-white fw-500" href="${baseUrl}/downloadZip?${params.join('&')}" target="_blanks">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download mb-1">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="7 10 12 15 17 10"></polyline>
              <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
          ${locales.downloadZip}
      </a>
  </div>
</div>
`;
