const error = $('.error-wrapper');
const errorMessage = $('.error-wrapper .message');
const close = $('.error-wrapper .btn.close');

const showError = message => {
  errorMessage.text(message);
  error.fadeIn(50);
  setTimeout(() => {
    hideError();
  }, 5 * 1000);
};
const hideError = () => {
  errorMessage.text('');
  error.fadeOut(50);
};

close.on('click', () => {
  hideError();
});

export default showError;
