import pond from './filepond';

$('body').on('click', '.btn.delete-all', () => {
  if (confirm(locales.confirmDeleteAll)) {
    const ids = pond.getFiles().map(file => file.serverId);
    $.ajax({
      method: 'POST',
      url: `${baseUrl}/deleteAll`,
      data: JSON.stringify({
        ids
      }),
      processData: false,
      contentType: 'application/json;charset=UTF-8',
      dataType: 'text',
      complete() {
        document.location = '/';
      },
      success(data) {
        console.log(data);
      }
    });
  }
});
