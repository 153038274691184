import 'jquery-filepond/filepond.jquery';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// setup filepond
FilePond.registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

const pond = FilePond.create(document.querySelector('#uploader'));
pond.setOptions({
  credits: false,
  server: {
    process: {
      url: `${baseUrl}/upload`,
      // forward response errors to filepond instance
      onerror: error => error
    },
    revert: `${baseUrl}/delete`
  },
  // display response errors
  labelFileProcessingError: function (error) {
    // error code 0: network errors
    if (error.code === 0) return locales.networkError;
    const errorMessage = JSON.parse(error.body).message;
    return errorMessage;
  },
  // "name" must match file name in server: $this->request->getFile('image');
  name: 'image',
  maxFileSize,
  acceptedFileTypes: ['image/jpeg'],
  allowMultiple: true,
  maxFiles: 10,
  maxParallelUploads: 10,
  // labels
  labelInvalidField: locales.labelInvalidField,
  labelFileWaitingForSize: locales.labelFileWaitingForSize,
  labelFileSizeNotAvailable: locales.labelFileSizeNotAvailable,
  labelFileLoading: locales.labelFileLoading,
  labelFileLoadError: locales.labelFileLoadError,
  labelFileProcessing: locales.labelFileProcessing,
  labelFileProcessingComplete: locales.labelFileProcessingComplete,
  labelFileProcessingAborted: locales.labelFileProcessingAborted,
  labelFileProcessingRevertError: locales.labelFileProcessingRevertError,
  labelFileRemoveError: locales.labelFileRemoveError,
  labelTapToCancel: locales.labelTapToCancel,
  labelTapToRetry: locales.labelTapToRetry,
  labelTapToUndo: locales.labelTapToUndo,
  labelButtonRemoveItem: locales.labelButtonRemoveItem,
  labelButtonAbortItemLoad: locales.labelButtonAbortItemLoad,
  labelButtonRetryItemLoad: locales.labelButtonRetryItemLoad,
  labelButtonAbortItemProcessing: locales.labelButtonAbortItemProcessing,
  labelButtonUndoItemProcessing: locales.labelButtonUndoItemProcessing,
  labelButtonRetryItemProcessing: locales.labelButtonRetryItemProcessing,
  labelButtonProcessItem: locales.labelButtonProcessItem,
  labelFileTypeNotAllowed: locales.labelFileTypeNotAllowed,
  fileValidateTypeLabelExpectedTypes:
    locales.fileValidateTypeLabelExpectedTypes,
  labelMaxFileSizeExceeded: locales.labelMaxFileSizeExceeded,
  labelMaxFileSize: locales.labelMaxFileSize,
  /* a hack so the upload zone will always fit its content */
  labelIdle: `<div class="d-flex flex-column py-5 px-3 w-100 wrapper">
    <span class="btn btn-primary browse-files fw-500 align-self-center text-white rounded-1 fs-4 p-4 py-3 filepond--label-action mb-3">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-plus text-white mb-1"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="12" y1="18" x2="12" y2="12"></line><line x1="9" y1="15" x2="15" y2="15"></line></svg>
      ${locales.browseFiles}
    </span>
    <p class="mb-0">${locales.dragNDrop} ${maxFileSize}</p>
  </div>`
});

/** handle events */

$('#uploader').on('FilePond:addfile', () => {
  $('.btn.compress').attr('disabled', true);
});
$('#uploader').on('FilePond:processfile', () => {
  if (pond.status === FilePond.Status.READY) {
    $('#compress-area').fadeIn(50);
    $('#compress-area')[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
    // multiple uploads are finished uploads
    $('.btn.compress').attr('disabled', false);
  }
});
$('#uploader').on('FilePond:removefile', () => {
  // in case an error occurred during upload and delete icon clicked
  if (pond.status === FilePond.Status.READY) {
    $('.btn.compress').attr('disabled', false);
  }
  if (pond.getFiles().length === 0) {
    $('#compress-area').fadeOut(50);
  }
});

export default pond;
