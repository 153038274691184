define([
    '../../../node_modules/jquery/src/core',
    '../../../node_modules/jquery/src/selector',
    // '../../../node_modules/jquery/src/traversing',
    // '../../../node_modules/jquery/src/callbacks',
    // '../../../node_modules/jquery/src/deferred',
    // '../../../node_modules/jquery/src/deferred/exceptionHook',
    // '../../../node_modules/jquery/src/core/ready',
    // '../../../node_modules/jquery/src/data',
    // '../../../node_modules/jquery/src/queue',
    // '../../../node_modules/jquery/src/queue/delay',
    '../../../node_modules/jquery/src/attributes',
    // '../../../node_modules/jquery/src/event',
    // '../../../node_modules/jquery/src/event/focusin',
    // '../../../node_modules/jquery/src/manipulation',
    // '../../../node_modules/jquery/src/manipulation/_evalUrl',
    // '../../../node_modules/jquery/src/wrap',
    // '../../../node_modules/jquery/src/css',
    // '../../../node_modules/jquery/src/css/hiddenVisibleSelectors',
    // '../../../node_modules/jquery/src/serialize',
    '../../../node_modules/jquery/src/ajax',
    '../../../node_modules/jquery/src/ajax/xhr',
    // '../../../node_modules/jquery/src/ajax/script',
    // '../../../node_modules/jquery/src/ajax/jsonp',
    // '../../../node_modules/jquery/src/ajax/load',
    // '../../../node_modules/jquery/src/core/parseXML',
    '../../../node_modules/jquery/src/core/parseHTML',
    '../../../node_modules/jquery/src/effects',
    // '../../../node_modules/jquery/src/effects/animatedSelector',
    // '../../../node_modules/jquery/src/offset',
    // '../../../node_modules/jquery/src/dimensions',
    // '../../../node_modules/jquery/src/deprecated',
    // '../../../node_modules/jquery/src/exports/amd',
    // '../../../node_modules/jquery/src/exports/global'
  ], function (jQuery) {
    'use strict';
  
    return jQuery;
  });